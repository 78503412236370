import React, {useEffect} from 'react'
import useForm from '../hooks/useForm'
import InputForm from './inputs/InputForm'

const Form = ({
  attributes = {},
  inputs,
  onSubmit,
  submitButtonProps = {},
  submitButtonChildren = <span className='indicator-label'>Finalizar Cadastro</span>,
}) => {
  const {init, submit, initialized, inputsState, callOnChange} = useForm(inputs)

  const sendForm = () => {
    const serializedData = submit()
    if (!serializedData) return false

    onSubmit(serializedData)
  }

  const btnDefaultProps = {
    type: 'button',
    className: 'btn btn-primary',
    id: 'form-generator-submit',
    disabled: false,
    onClick: sendForm,
    ...submitButtonProps,
  }

  useEffect(() => {
    if (!initialized) init()
  }, [inputsState])

  return (
    <form {...attributes}>
      {initialized && (
        <>
          <>
            {inputsState.map((input, index) => (
              <InputForm {...input} key={`input-form-${index}`} onChange={(e) => callOnChange(e, index)} />
            ))}
          </>
        </>
      )}
      <button {...btnDefaultProps}>{submitButtonChildren}</button>
    </form>
  )
}

export default Form
