import React from 'react'

const Element = (props) => {
  const {children = {}} = props

  var data = {
    ...props,
  }

  delete data.setValue
  delete data.labelAttributes
  delete data.containerAttributes
  delete data.validation
  delete data.mask

  return <div {...data}>{children}</div>
}

export default Element
