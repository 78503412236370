import {FC, useState, useEffect} from 'react'
import {WithChildren} from '../../../_metronic/helpers'
import ConfigContext from './ConfigContext'
import {defaultValues} from './defaultValues'
import {baseUrl, sendRequest} from '../../../Helpers/Requests'

const ConfigProvider: FC<WithChildren> = ({children}) => {
  const [config, setConfig] = useState<object>(defaultValues)

  const getInitialData = () => {
    const cfg = localStorage.getItem('@jSconfig')
    if (!cfg) return false

    const data = JSON.parse(cfg)
    setConfig(data)
    setInitialPageData(data)
  }

  const setInitialPageData = (data: any) => {
    let link = document.querySelector("link[rel~='icon']")
    if (link) link.setAttribute('href', baseUrl(data.favicon))
    document.title = 'Backoffice ' + data.empresa
  }

  const saveData = (data = null) => {
    const toSave = !data ? config : data
    localStorage.setItem('@jSconfig', JSON.stringify(toSave))
  }

  const getConfigurations = async () => {
    const response = await sendRequest('config', 'GET')

    if (response.tipo !== 'success') return false

    saveData(response)
    setInitialPageData(response)
    setConfig(response)
  }

  useEffect(() => {
    getInitialData()
    getConfigurations()
  }, [])

  return <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider>
}
export {ConfigProvider}
