function cnpjMask(v, separador = '.') {
  v = v.replace(/\D/g, '')
  v = v.length > 14 ? v.substring(0, 14) : v
  v = v.replace(/(\d{2})(\d)/, '$1' + separador + '$2')
  v = v.replace(/(\d{3})(\d)/, '$1' + separador + '$2')
  v = v.replace(/(\d{3})(\d)/, '$1/$2')
  v = v.replace(/(\d{4})(\d)/, '$1-$2')
  return v
}

function cpfMask(v, separador = '.') {
  v = v.replace(/\D/g, '')
  v = v.length > 11 ? v.substring(0, 11) : v
  v = v.replace(/(\d{3})(\d)/, '$1' + separador + '$2')
  v = v.replace(/(\d{3})(\d)/, '$1' + separador + '$2')
  v = v.replace(/(\d{3})(\d)/, '$1-$2')
  return v
}

function phoneMask(v) {
  v = v.replace(/\D/g, '')
  v = v.replace(/^(\d{2})(\d)/g, '($1) $2')
  v = v.replace(/(\d)(\d{4})$/, '$1-$2')
  return v
}

function dateMask(v, separador = '/', tipo = 'pt-BR') {
  if (tipo === 'pt-BR' || tipo === 'en-US') {
    v = v.replace(/\D/g, '')
    v = v.length > 8 ? v.substring(0, 8) : v
    v = v.replace(/(\d{2})(\d)/, '$1' + separador + '$2')
    v = v.replace(/(\d{2})(\d)/, '$1' + separador + '$2')
    return v
  } else {
    v = v.replace(/\D/g, '')
    v = v.length > 8 ? v.substring(0, 8) : v
    v = v.replace(/(\d{4})(\d)/, '$1' + separador + '$2')
    v = v.replace(/(\d{2})(\d)/, '$1' + separador + '$2')
    return v
  }
}

function cepMask(v) {
  v = v.length > 9 ? v.substring(0, 9) : v
  return v.replace(/\D/g, '').replace(/(\d{5})(\d)/, '$1-$2')
}

function walletMask(v) {
  return v.replace(/\s/g, '')
}


export {
  cnpjMask,
  cpfMask,
  phoneMask,
  dateMask,
  cepMask,
  walletMask,
}
