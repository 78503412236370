import React from 'react'
import {idGenerator} from '../../utils/idGenerator'

const InputRadio = (props) => {
  const {
    name = '',
    value = null,
    label = null,
    containerAttributes = {},
    labelAttributes = {},
  } = props;

  var data = {
    className: 'form-check-input',
    name: name,
    type: 'radio',
    value: value,
    ...props,
  }

  if (!data.errors.valido) {
    data.className += ' border-danger'
  }

  const container = {
    className: 'form-check form-check-custom form-check-solid',
    ...containerAttributes,
  }

  const labelDt = {
    className: 'form-check-label',
    htmlFor: data.id,
    ...labelAttributes,
  }

  delete data.setValue
  delete data.labelAttributes
  delete data.containerAttributes
  delete data.validation
  delete data.mask

  return (
    <div {...container}>
      <input {...data} />
      {label && <label {...labelDt}>{label}</label>}
    </div>
  )
}

export default InputRadio
