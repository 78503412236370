import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'


const chartColors = {
  diario: getCSSVariableValue('--kt-success'),
  indicacao: getCSSVariableValue('--kt-primary'),
  binario: getCSSVariableValue('--kt-warning'),
  unilevel: getCSSVariableValue('--kt-danger'),
  undefined: "#2A2A3C",
}

const drawCircle = function (ctx, radius, color, lineWidth, percent) {
  percent = Math.min(Math.max(0, percent || 1), 1)
  if (!ctx) {
    return
  }

  ctx.beginPath()
  ctx.arc(0, 0, radius, 0, Math.PI * 2 * percent, false)
  ctx.strokeStyle = color
  ctx.lineCap = 'butt' // butt, round or square
  ctx.lineWidth = lineWidth
  ctx.stroke()
}

const getChartValueData = (config, values) => {
  let validValues = []
  let totalSum = 0
  const keys = Object.keys(values)
  keys.forEach((key) => {
    if (!config[key]) return true

    totalSum += values[key]
    validValues.push({value: values[key], color: chartColors[key]})
  })

  validValues = validValues.sort((a, b) => {
    return a.value > b.value ? -1 : 1
  })

  return {
    total: totalSum,
    values: validValues,
  }
}

export const initChart = function (
  config,
  values,
  chartSize = 400,
  chartLine = 31,
  chartRotate = 145
) {
  const el = document.getElementById('kt_card_widget_17_chart')
  if (!el) {
    return
  }
  el.innerHTML = ''

  var options = {
    size: chartSize,
    lineWidth: chartLine,
    rotate: chartRotate,
    //percent:  el.getAttribute('data-kt-percent') ,
  }

  const canvas = document.createElement('canvas')
  const span = document.createElement('span')

  const ctx = canvas.getContext('2d')
  canvas.width = canvas.height = options.size

  el.appendChild(span)
  el.appendChild(canvas)

  // @ts-ignore
  ctx.translate(options.size / 2, options.size / 2) // change center
  // @ts-ignore
  ctx.rotate((-1 / 2 + options.rotate / 180) * Math.PI) // rotate -90 deg

  //imd = ctx.getImageData(0, 0, 240, 240);
  const radius = (options.size - options.lineWidth) / 2

  // Init 2
  let chartValueData = getChartValueData(config, values)

  chartValueData.values.forEach((val, i) => {
    if (i === 0) {
      if (val.value > 0) drawCircle(ctx, radius, val.color, options.lineWidth, 1)
      else drawCircle(ctx, radius, chartColors.undefined, options.lineWidth, 1)
      return true
    }

    if (val.value <= 0) return true;

    let toSum = 0;
    for (let k=i + 1; k<chartValueData.length; k++) {
        toSum += chartValueData.values[k].value;
    }
    
    let actualValue = val.value + toSum;
    drawCircle(ctx, radius, val.color, options.lineWidth, actualValue / chartValueData.total)
  })
}
