import {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link, useParams} from 'react-router-dom'
import {useFormik} from 'formik'
import {requestPassword} from '../core/_requests'
import { toast } from 'react-hot-toast'
import { sendRequest } from '../../../../Helpers/Requests'

const initialValues = {
  email: 'admin@demo.com',
}

const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .email('Wrong email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
})

export function RecoveryPassword() {
  const [loading, setLoading] = useState(false)
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const formik = useFormik({
    initialValues,
    validationSchema: forgotPasswordSchema,
    onSubmit: (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      setHasErrors(undefined)
      setTimeout(() => {
        requestPassword(values.email)
          .then(({data: {result}}) => {
            setHasErrors(false)
            setLoading(false)
          })
          .catch(() => {
            setHasErrors(true)
            setLoading(false)
            setSubmitting(false)
            setStatus('The login detail is incorrect')
          })
      }, 1000)
    },
  })

  const [ senha, setSenha ] = useState('')
  const [ confirmar, setConfirmar ] = useState('')
  const [ success, setSuccess ] = useState(false)

  const {hash} = useParams()

  const handleRecovery = async () => {
    if (hash) {
      if (senha != '' && confirmar != '') {
        if (senha === confirmar) {
          const request = await sendRequest(`recuperar/${hash}`, 'POST', {
              senha
            })
      
            if (request.tipo == "success") {
              setSuccess(true)
            }
        } else {
          toast.error('As senhas não coincidem.')
        }
      } else {
        toast.error('Você precisa preencher todos os campos para recuperar sua senha.')
      }
    } else {
      toast.error('Link de recuperação inválido')
    }
  }

  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_password_reset_form'
      onSubmit={formik.handleSubmit}
    >
      <div className='text-center mb-10'>
        {/* begin::Title */}
        <h1 className='text-dark fw-bolder mb-3'>Concluir recuperação de senha</h1>
        {/* end::Title */}

        {/* begin::Link */}
        <div className='text-gray-500 fw-semibold fs-6'>
          Informe seu usuário para recupera-la!
        </div>
        {/* end::Link */}
      </div>

      
      {success &&
        <div className='mb-10 bg-light-success p-8 rounded'>
          <div className='text-success'>Senha alterada com sucesso, por favor, tente fazer login!</div>
        </div>
      }
      
      {/* end::Title */}

      {/* begin::Form group */}
      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-gray-900 fs-6'>Senha</label>
        <input
          type='password'
          placeholder=''
          autoComplete='off'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control bg-transparent',
          )}
          value={senha}
          onChange={t=>setSenha(t.target.value)}
        />
      </div>
      {/* end::Form group */}

      <div className='fv-row mb-8'>
        <label className='form-label fw-bolder text-gray-900 fs-6'>Confirmar Senha</label>
        <input
          type='password'
          placeholder=''
          autoComplete='off'
          {...formik.getFieldProps('email')}
          className={clsx(
            'form-control bg-transparent',
          )}
          value={confirmar}
          onChange={t=>setConfirmar(t.target.value)}
        />
      </div>

      {/* begin::Form group */}
      <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
        <button type='submit' id='' className='btn btn-primary me-4'
          onClick={handleRecovery}
        >
          <span className='indicator-label'>Recuperar Senha</span>
          {loading && (
            <span className='indicator-progress'>
              Aguarde um momento...
              <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
            </span>
          )}
        </button>
        <Link to='/auth/login'>
          <button
            type='button'
            id='kt_login_password_reset_form_cancel_button'
            className='btn btn-light'
            disabled={formik.isSubmitting || !formik.isValid}
          >
            Cancelar
          </button>
        </Link>{' '}
      </div>
      {/* end::Form group */}
    </form>
  )
}
