import { PageTitle } from "../../../_metronic/layout/core"
import { useState, useEffect } from 'react'
import { sendRequest } from "../../../Helpers/Requests"
import { Modal } from "react-bootstrap"
import { KTSVG } from "../../../_metronic/helpers"
import { toast } from "react-hot-toast"

const MinhaCarteira = () => {

    const [rows, setRows] = useState<any>(null)
    const [walletVerify, setWalletVerify] = useState<any>(null)
    const [walletDelete, setWalletDelete] = useState<any>(null)
    const [modalNewWallet, setModalNewWallet] = useState<boolean>(false)
    const [loading, setLoading] = useState<boolean>(false)

    const [carteira, setCarteira] = useState<any>('')

    const renderRows = (item: any, index: number) => {
        return (
            <tr key={index}>
                <td className="notranslate">#{item.id}</td>
                <td className="notranslate">{item.carteira}</td>
                <td>
                    {item.ativa === 0 &&
                        <div className="badge badge-danger">Inativa</div>
                        ||
                        <div className="badge badge-success">Ativa</div>
                    }
                </td>
                <td>
                    {item.ativa === 0 &&
                        <button className="btn btn-primary me-5" onClick={() => setWalletVerify(item)}>Verificar Carteira</button>
                    }
                    <button className="btn btn-danger" onClick={() => setWalletDelete(item)}>Remover Carteira</button>
                </td>
            </tr>
        )
    }

    const loadWallets = async () => {
        const req = await sendRequest(`carteiras`, 'GET')
        const response = await req

        if (response.tipo === 'success') {
            setRows(response.carteiras)
        }
    }

    const handleSendVerify = async () => {
        setLoading(true)
        const req = await sendRequest(`carteiras/notificar/${walletVerify.id}`)
        const response = await req

        if (response.tipo === 'success') {
            setWalletVerify(null)
        }

        setLoading(false)
    }

    const handleDelete = async () => {
        setLoading(true)
        const req = await sendRequest(`carteiras/remover/${walletDelete.id}`, 'DELETE')
        const response = await req

        if (response.tipo === 'success') {
            setWalletDelete(null)
            loadWallets()
        }

        setLoading(false)
    }

    const handleNewWallet = async () => {
        setLoading(true)

        if (carteira.length > 0) {
            const req = await sendRequest(`carteiras`, 'POST', {
                carteira
            })

            const response = await req

            if (response.tipo === 'success') {
                setModalNewWallet(false)
                setCarteira('')
                loadWallets()
            }
        } else {
            toast.error('Você precisa informar um endereço de carteira para inserir uma nova carteira.')
        }

        setLoading(false)
    }

    useEffect(() => {
        loadWallets()
    }, [])

    return (
        <>
            <PageTitle breadcrumbs={[]}>Minha Carteira</PageTitle>

            <div className="row g-5 g-xl-10 mb-5 mb-xl-10">
                <div className="col-sm-12">
                    <div className="card card-flush">
                        <div className="card-header d-flex justify-content-end pt-5">
                            <button className="btn btn-primary" onClick={() => setModalNewWallet(true)}>Nova Carteira</button>
                        </div>
                        <div className="card-body d-flex align-items-end pt-5">
                            <div className='table-responsive' style={{ width: '100%' }}>
                                {/* begin::Table */}
                                <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                                    {/* begin::Table head */}
                                    <thead>
                                        <tr className='fw-bold text-muted'>
                                            <th>
                                                <div className='form-check form-check-sm form-check-custom form-check-solid'>
                                                    ID
                                                </div>
                                            </th>
                                            <th>Hash</th>
                                            <th>Status</th>
                                            <th>Opções</th>
                                        </tr>
                                    </thead>
                                    {/* end::Table head */}
                                    {/* begin::Table body */}
                                    <tbody>
                                        {rows !== null &&
                                            <>
                                                {rows.map((item: any, index: number) => renderRows(item, index))}
                                            </>
                                        }
                                    </tbody>
                                    {/* end::Table body */}
                                </table>
                                {rows == null &&
                                    <div className="alert alert-warning">
                                        Você não possui nenhuma carteira cadastrada.
                                    </div>
                                }
                                {/* end::Table */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Modal
                id='kt_modal_create_app'
                tabIndex={-1}
                aria-hidden='true'
                dialogClassName='modal-dialog modal-dialog-centered mw-500px'
                show={(walletVerify != null)}
                onHide={() => setWalletVerify(null)}
                backdrop={true}
            >
                {walletVerify != null &&
                    <>
                        <div className='modal-header'>
                            <h2>Verificar carteira</h2>
                            {/* begin::Close */}
                            <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={() => setWalletVerify(null)}>
                                <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                            </div>
                            {/* end::Close */}
                        </div>
                        <div className='modal-body py-lg-10 px-lg-10'>
                            Deseja realmente reenviar a confirmação da carteira ?
                        </div>

                        <div className="modal-footer py-lg-10 px-lg-10">
                            <button type='reset' className='btn btn-primary me-3' onClick={handleSendVerify} disabled={loading}>
                                {loading &&
                                    <>
                                        <span className='indicator-progress' style={{ display: 'block' }}>
                                            Aguarde um instante...
                                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                        </span>
                                    </>
                                    ||
                                    <>Reenviar</>
                                }
                            </button>
                            <button className="btn btn-danger" onClick={() => setWalletVerify(null)}>
                                Cancelar
                            </button>

                        </div>
                    </>
                }
            </Modal>

            <Modal
                id='kt_modal_create_app'
                tabIndex={-1}
                aria-hidden='true'
                dialogClassName='modal-dialog modal-dialog-centered mw-500px'
                show={(walletDelete != null)}
                onHide={() => setWalletDelete(null)}
                backdrop={true}
            >
                {walletDelete != null &&
                    <>
                        <div className='modal-header'>
                            <h2>Excluir carteira</h2>
                            {/* begin::Close */}
                            <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={() => setWalletDelete(null)}>
                                <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                            </div>
                            {/* end::Close */}
                        </div>
                        <div className='modal-body py-lg-10 px-lg-10'>
                            Deseja realmente reenviar a confirmação da carteira ?
                        </div>

                        <div className="modal-footer py-lg-10 px-lg-10">
                            <button type='reset' className='btn btn-primary me-3' onClick={handleDelete} disabled={loading}>
                                {loading &&
                                    <>
                                        <span className='indicator-progress' style={{ display: 'block' }}>
                                            Aguarde um instante...
                                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                        </span>
                                    </>
                                    ||
                                    <>Excluir</>
                                }
                            </button>
                            <button className="btn btn-danger" onClick={() => setWalletDelete(null)}>
                                Cancelar
                            </button>
                        </div>
                    </>
                }
            </Modal>

            <Modal
                id='kt_modal_create_app'
                tabIndex={-1}
                aria-hidden='true'
                dialogClassName='modal-dialog modal-dialog-centered mw-500px'
                show={modalNewWallet}
                onHide={() => setModalNewWallet(false)}
                backdrop={true}
            >
                <div className='modal-header'>
                    <h2>Nova Carteira ou Chave Pix</h2>
                    {/* begin::Close */}
                    <div className='btn btn-sm btn-icon btn-active-color-primary' onClick={() => setModalNewWallet(false)}>
                        <KTSVG className='svg-icon-1' path='/media/icons/duotune/arrows/arr061.svg' />
                    </div>
                    {/* end::Close */}
                </div>
                <div className='modal-body py-lg-10 px-lg-10'>
                    <label>Carteira</label>
                    <input className="form-control" placeholder="Chave pix ou Carteira usdt(trc20)" value={carteira} onChange={e => setCarteira(e.target.value)}/>
                </div>

                <div className="modal-footer py-lg-10 px-lg-10">
                    <button type='reset' className='btn btn-primary me-3' onClick={handleNewWallet} disabled={loading}>
                        {loading &&
                            <>
                                <span className='indicator-progress' style={{ display: 'block' }}>
                                    Aguarde um instante...
                                    <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                </span>
                            </>
                            ||
                            <>Cadastrar Carteira</>
                        }
                    </button>
                    <button className="btn btn-danger" onClick={() => setModalNewWallet(false)}>
                        Cancelar
                    </button>
                </div>
            </Modal>

        </>
    )
}

export { MinhaCarteira }