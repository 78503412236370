export const getColumns = (binario) => {
  let data = [
    {
      sortable: true,
      label: 'ID',
      sortableValue: 'id',
      className: 'form-check form-check-sm form-check-custom form-check-solid',
      width: '25px',
    },
    {
      sortable: true,
      label: 'Usuário',
      sortableValue: 'login',
      minWidth: '150px',
    },
    {
      sortable: true,
      label: 'Status',
      sortableValue: 'ativo',
      minWidth: '150px',
    },
    {
      sortable: true,
      label: 'Indicador',
      sortableValue: 'indicator',
      minWidth: '150px',
    },
  ]
  if (binario) {
    data.push({
      sortable: true,
      label: 'Perna',
      sortableValue: 'perna',
      minWidth: '150px',
    })
  }

  return data;
}
