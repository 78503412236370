import React, {useEffect} from 'react'
import { idGenerator } from '../utils/idGenerator'

export default function useForm(inputs) {
  const [initialized, setInitialized] = React.useState(false)
  const [inputsState, setInputsState] = React.useState([])

  const defaultValidation = () => {
    return {valido: true, msg: ''}
  }

  const defaultMask = (val) => {
    return val
  }

  const callOnChange = (event, index) => {
    let maskedValue = inputsState[index].mask(event.target.value)

    let nInputs = [...inputsState]
    nInputs[index].value = maskedValue
    nInputs[index].errors = inputsState[index].validation(nInputs[index])
    setInputsState(nInputs)
  }

  const callSetValue = (value, index) => {
    let maskedValue = inputsState[index].mask(value)

    let nInputs = [...inputsState]
    nInputs[index].value = maskedValue
    nInputs[index].errors = inputsState[index].validation(nInputs[index])
    setInputsState(nInputs)
  }

  const init = (inputs) => {
    if (typeof inputs === 'undefined') return true
    let nInputs = []
    inputs.forEach((input, index) => {
      nInputs.push({
        ...input,
        id: typeof input.id !== 'undefined' ? input.id : idGenerator(),
        value: typeof input.value !== 'undefined' ? input.value : '',
        errors: defaultValidation(),
        validation: typeof input.validation === 'function' ? input.validation : defaultValidation,
        mask: typeof input.mask === 'function' ? input.mask : defaultMask,
      })
    })

    setInputsState(nInputs)
    setInitialized(true)
  }

  const serialize = () => {
    let jsonData = {}
    inputsState.forEach((data) => {
      if (typeof data.value === 'undefined') return true;
      jsonData[data.name] = data.value
    })
    return jsonData
  }

  const submit = () => {
    let nInputs = []
    let hasError = false
    let hasFocused = false
    inputsState.forEach((data) => {
      let nInput = {...data}
      nInput.errors = nInput.validation(nInput)
      if (!nInput.errors.valido) {
        hasError = true
        if (!hasFocused) {
          document.querySelector(`#${nInput.id}`).focus()
          hasFocused = true
        }
      }
      nInputs.push(nInput)
    })
    if (hasError) {
      setInputsState(nInputs)
      return null
    }

    return serialize()
  }

  useEffect(() => {
    init(inputs)
  }, [])

  return {
    init,
    submit,
    serialize,
    callOnChange,
    callSetValue,
    initialized,
    inputsState,
  }
}
