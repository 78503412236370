import React from "react";
import './index.css'

const RedeSeparador = ({ tipo }) => {
  return (
    <div className={`Rede--linha_separador${tipo}`}>
      <div className="Rede--linha">
        <div className="Rede--separadorCima"></div>
      </div>
      <div className="Rede--coluna">
        <div className="Rede--barraBaixo_esquerda"></div>
        <div className="Rede--barraBaixo_direita"></div>
      </div>
    </div>
  );
};

export default RedeSeparador;
