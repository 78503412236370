import {useEffect, useState} from 'react'
import {toast} from 'react-hot-toast'
import {baseUrl, sendRequest} from '../../../Helpers/Requests'
import {PageTitle} from '../../../_metronic/layout/core'
import {useAuth} from '../../modules/auth'
import {convertFileToBase64} from '../../../Helpers/Utils'
import {unstable_extendSxProp} from '@mui/system'
import { phoneMask } from '../../modules/form/masks/masks'

const Perfil = () => {
  const {currentUser, setCurrentUser} = useAuth()

  const [avatar, setAvatar] = useState<any>(baseUrl(currentUser?.pic))
  const [nome, setNome] = useState<any>(currentUser?.fullname)
  const [email, setEmail] = useState<any>(currentUser?.email)
  const [telefone, setTelefone] = useState<any>(currentUser?.phone)
  const [hasFile, setHasFile] = useState<boolean>(false)

  const handleSave = async () => {
    if (nome !== '' && nome != null) {
      let newData: {
        nome: string
        email?: string
        celular?: string
        avatar?: string
      }
      newData = {
        nome: nome,
        email: email,
        celular: telefone,
      }

      if (hasFile) newData['avatar'] = avatar

      const req = await sendRequest('usuario', 'PUT', newData)

      if (req.tipo === 'success') {
        toast.success('Seus dados foram alterados com sucesso!')

        const newCurrentUser = {
          ...currentUser,
          fullname: nome,
          email: email,
          pic: req.usuario.imagem,
        }

        setCurrentUser(newCurrentUser)
      }
    } else {
      toast.error('Preencha todos os campos')
    }
  }

  const changedFile = async (e: any) => {
    if (typeof e.target.files[0] === 'undefined') {
      setAvatar(baseUrl(currentUser?.pic))
      setHasFile(false)
      return
    }

    const base64 = await convertFileToBase64(e.target.files[0])
    setAvatar(base64)
    setHasFile(true)
  }

  return (
    <>
      <PageTitle>Perfil</PageTitle>
      <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
        <div className='col-sm-12'>
          <div className='card card-flush'>
            <div className='card-header d-flex mt-5'>
              <h3>Alterar dados</h3>
            </div>
            <div className='card-body d-flex align-items-end pt-3'>
              <div className='row' style={{flex: 1}}>
                <div className='col-lg-12 mt-5'>
                  <label>Avatar</label>
                  <div className='d-flex align-items-center'>
                    <div className='d-block me-5 symbol symbol-40px'>
                      <img src={avatar} alt='Foto de Perfil' />
                    </div>
                    <input type='file' className='form-control' onChange={changedFile} />
                  </div>
                </div>
                <div className='col-lg-12 mt-5'>
                  <label>Nome</label>
                  <input
                    className='form-control'
                    value={nome}
                    onChange={(e) => setNome(e.target.value)}
                  />
                </div>
                <div className='col-lg-12 mt-5'>
                  <label>E-mail</label>
                  <input
                    className='form-control'
                    value={email}
                    type='email'
                    onChange={(e) => setEmail(e.target.value)}
                    readOnly
                  />
                </div>
                <div className='col-lg-12 mt-5'>
                  <label>Telefone</label>
                  <input
                    className='form-control'
                    value={telefone}
                    onChange={(e) => setTelefone(phoneMask(e.target.value))}
                    readOnly
                  />
                </div>
              </div>
            </div>
            <div className='card-footer d-flex justify-content-end pt-3'>
              <button className='btn btn-primary' onClick={handleSave}>
                Salvar
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export {Perfil}
