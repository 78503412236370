export const getColumns = () => {
  let data = [
    {
      sortable: true,
      label: 'ID',
      sortableValue: 'id',
      className: 'form-check form-check-sm form-check-custom form-check-solid',
      width: '25px',
    },
    {
      sortable: true,
      label: 'Valor',
      sortableValue: 'valor',
    },
    {
      sortable: true,
      label: 'Tipo',
      sortableValue: 'tipo',
    },
    {
      sortable: true,
      label: 'Descrição',
      sortableValue: 'descricao',
    },
    {
      sortable: true,
      label: 'Movimentação',
      sortableValue: 'balanco',
    },
    {
      sortable: true,
      label: 'Data',
      sortableValue: 'created_at',
    }
  ]

  return data;
}
