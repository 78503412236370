import {isFunction} from '../utils/utils'

const Column = ({column, index, sortIndex, sortOrder, sort, initialOrder}) => {
  const {
    sortable = true,
    style = {},
    label = '',
    sortableValue = null,
    render = null,
    className = '',
    width = null,
    minWidth = null,
  } = column

  const sortedBy = sortIndex === index ? sortOrder : initialOrder

  const renderIcon = () => {
    if (sortedBy === 'asc')
      return <i className={`fa fa-arrow-down ${sortIndex === index ? 'text-primary' : ''}`}></i>
    return <i className={`fa fa-arrow-up ${sortIndex === index ? 'text-primary' : ''}`}></i>
  }

  const getStyles = () => {
    let style = {}
    if (width) style.width = width
    if (minWidth) style.minWidth = minWidth
    return style;
  }

  const handleClick = () => {
    if (!sortable) return true

    sort(index)
  }

  return (
    <th style={getStyles()}>
      <div
        onClick={handleClick}
        className={`d-flex w-100 ${sortable ? 'cursor-pointer' : ''} ${sortIndex === index ? 'text-primary' : ''}`}
      >
        {sortable && <div className='table-column-sort pe-3'>{renderIcon()}</div>}
        {isFunction(render) ? render(column) : label}
      </div>
    </th>
  )
}

export default Column
