import {useEffect} from 'react'
import {Navigate, Routes} from 'react-router-dom'
import { useNavigate } from 'react-router-dom'

export function Clear() {
  const navigate = useNavigate();
  useEffect(() => {
    localStorage.removeItem('@jSconfig')
    navigate('/auth');
  }, [])

  return (
    <>
    </>
  )
}
